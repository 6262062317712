// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
//   return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
//   return store.getters.auth.isLoggedIn
//   const { token } = store.state.auth
//   return !!token
  return store.getters['auth/isLoggedIn']
}

// export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getUserData = () => store.state['auth/userData']

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUserOld = userRole => {
  if (userRole === 'admin') return '/'

  //   if (userRole === 'client') return { name: 'access-control' }
  // Ver Rutas: src/router/routes/dashboard.js
  if (userRole === 'staff') return { name: 'seller' }
  if (userRole === 'seller') return { name: 'seller' }
  if (userRole === 'purchaser') return { name: 'purchaser' }

  return { name: 'auth-login' }
}

// TODO - Por ahora no se usa el rol
export const getHomeRouteForLoggedInUser = userRole => {
  // Default '/' => Send to dashboard-analytics
  let goToPath = { path: '/' }
  if (userRole === 'admin') {
    goToPath = { name: 'home' }
  }
  if (userRole === 'staff') {
    goToPath = { name: 'home' }
  }

  return goToPath
}
