<template>
  <div class="auth-wrapper auth-v1 pa-0">
    <div class="auth-inner">
      <!-- brand logo -->
      <!-- <app-bar-logo></app-bar-logo> -->

      <!-- Solo una columna -->
      <v-row>
        <v-col
          cols="12"
          lg="12"
          class="mx-auto"
        >
          <v-card
            flat
            class="rounded-0"
          >
            <v-card-text>
              <p
                class="text-2xl font-weight-semibold text--primary mb-2"
              >
                <!-- Welcome to FishNet! 👋🏻 -->
                {{ t('Login.wellcome_title') }}
              </p>
              <p class="mb-2">
                <!-- Please sign-in to your account and start the adventure -->
                {{ t('Login.wellcome_description') }}
              </p>
            </v-card-text>

            <!-- login form -->
            <v-card-text>
              <v-form
                ref="form"
                @submit.prevent="handleFormSubmit"
              >
                <v-text-field
                  v-model="username"
                  outlined
                  :label="t('Login.form.username')"
                  :placeholder="t('Login.form.username_placeholder')"
                  hide-details="auto"
                  class="mb-6"
                  :error-messages="errorMessages.username"
                  :rules="[required]"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :label="t('Login.form.password')"
                  :placeholder="t('Login.form.password_placeholder')"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  hide-details="auto"
                  class="mb-2"
                  :error-messages="errorMessages.password"
                  :rules="[required]"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>

                <div
                  class="d-flex align-center justify-space-between flex-wrap"
                >
                  <v-checkbox
                    v-model="remember"
                    hide-details
                    :label="t('Login.form.rememberme')"
                    class="mt-0"
                  >
                  </v-checkbox>

                  <!-- forget link -->
                  <router-link
                    :to="{name:'auth-forgot-password'}"
                    class="ms-3 text-right"
                  >
                    {{ t('Login.forgot_password') }}
                  </router-link>
                </div>

                <v-btn
                  block
                  color="primary"
                  type="submit"
                  class="mt-6"
                >
                  <div v-if="loading">
                    <v-progress-circular
                      indeterminate
                      color="red"
                      @click.prevent="onCancelRequest"
                    >
                      <v-icon>
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-progress-circular>
                  </div>
                  <span v-else>{{ t('Login.form.submit') }}</span>
                </v-btn>
              </v-form>
            </v-card-text>

            <!-- create new account  -->
            <v-card-text
              class="d-flex align-center justify-center flex-wrap mt-2"
            >
              <p class="mb-0 me-2">
                <!-- New on our platform? -->
                {{ t('Login.new_on_platform') }}
              </p>
              <router-link :to="{name:'auth-register'}">
                {{ t('Login.create_an_account') }}
              </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- </v-col>
      </v-row> -->
    </div>
  </div>
</template>

<script>
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'

// Components
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import store from '@/store'

// import { useUtils as useI18nUtils } from '@/plugins/i18n'

// Components
// import AppBarLogo from '@/components/AppBar/AppBarLogo.vue'

// UI Services
import useAppConfigUser from '@/services/app/useAppConfigUser'

// Services
import useJwt from '@/services/api/modules/auth/useJwt'
import { getHomeRouteForLoggedInUser } from '@/services/api/modules/auth/utils'
import useAuthHandler from '@/views/pages/auth/useAuthHandler'

export default {
  components: {
    // AppBarLogo,
  },
  setup() {
    // Uses
    const { router } = useRouter()
    const { useI18n } = useI18nUtils()
    const { i18n } = useI18n()
    const { t } = useI18nUtils()
    const { sendAuthNotification } = useAuthHandler()

    // appMode [seller/purchaser]
    const { appMode } = useAppConfigUser()

    // Data
    const username = ref(localStorage.getItem('username'))
    const password = ref(localStorage.getItem('password'))
    const remember = ref(localStorage.getItem('remember'))
    const errorMessages = ref([])
    const isPasswordVisible = ref(false)

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onUserLogin()
      // eslint-disable-next-line no-use-before-define
      // onUserLoginFake()
      //   .then(response => {
      //     debugger
      //     sendAuthNotification(response.message, 'success')
      //   })
      //   .catch(error => {
      //     debugger
      //     sendAuthNotification(error.message, 'error')
      //   })
    }

    // eslint-disable-next-line no-unused-vars
    const onUserLoginFake = () => new Promise((resolve, reject) => {
      loading.value = !loading.value

      // ok
      // sendAuthNotification('Login', 'success')
      // resolve(loading)

      // Error
      // sendAuthNotification('Login', 'error')
      const error = {
        message: 'Check your password',
        error: {
          username: null,
          password: 'Check your password',
        },
      }
      reject(error)
    })

    const onCancelRequest = () => new Promise(resolve => {
      loading.value = false
      resolve()
    })

    // eslint-disable-next-line no-unused-vars
    const onUserLogin = () => {
      loading.value = true
      useJwt
        .accessLogin({
          username: username.value,
          password: password.value,
        })
        .then(response => {
          const { userData } = response.data
          if (userData) {
            useJwt.setRememberMe(remember.value, username.value, password.value)

            // Set AppMode (AppMode fue calculado en el store y ahora lo replicamos a las instacias $vuetify)
            appMode.value = store.state.user?.appMode // Reload

            // Preparamos el sistema de traducciones para el nuevo usuario
            // La proxima vez que se cargue la app, el i18n se iniciará con el localStorage
            if (userData.language !== '' && i18n.locale !== userData.language) {
              i18n.locale = userData.language
            }

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            router
              .replace(getHomeRouteForLoggedInUser(userData.companyRole))
              .then(() => {
                sendAuthNotification(`Welcome ${userData.fullName || userData.username}`, 'success')
              })
              .catch(error => {
                // refFormObserver.setErrors(error.response.data.error)
                // refFormObserver.value.setErrors({ username: ['Something went wrong'] })
                // errorMessages.value = { username: ['Something went wrong'] }
                if (error.response && error.response.data && error.response.data.error) {
                  errorMessages.value = { username: ['Something went wrong'] }

                  sendAuthNotification(error.response.data.error.message, 'error')
                }
              })
          } else {
            // refFormObserver.value.setErrors(response.data.error)
            sendAuthNotification(response.data.error, 'error')
          }

          loading.value = false
        })
        .catch(error => {
          // loading.value = false

          // Checking... si es un error de dominio lo pasamos al formulario
          if (error.response && error.response.data && error.response.data.error) {
            // refFormObserver.value.setErrors(error.response.data.error)
            errorMessages.value = error.response.data.error
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      // i18n
      t,

      // Form validation
      form,
      handleFormSubmit,
      required,
      emailValidator,
      passwordValidator,

      // Data
      username,
      password,
      remember,
      errorMessages,
      isPasswordVisible,

      // Interface events
      loading,
      onCancelRequest,
      onUserLogin, // No se usa en el Template

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiClose,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
